/* src/styles/AssessmentDetail.css */

/* Stili per le card nella sezione Valutazione Media e Partecipanti */
.card-details {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Aggiunge un'ombra leggera */
  transition: transform 0.2s ease; /* Aggiunge una transizione per un effetto hover */
  border-radius: 0px; /* Rende gli angoli arrotondati */
  border: 1px;
  border: 1px solid #e7e5e4; /* Aggiungi il bordo con il colore specificato */
  padding: 20px; /* Aggiunge un po' di padding interno */
  text-align: center; /* Allinea il contenuto al centro */
  background-color: white; /* Sfondo bianco per le card */
}

.card:hover {
  transform: translateY(-5px); /* Leggero sollevamento al passaggio del mouse */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2); /* Aumenta l'ombra al passaggio del mouse */
}

/* Stili per il contenuto interno delle card */
.card .ant-card-meta-title {
  font-size: 24px; /* Dimensione del testo per i valori (ad es. 64%) */
  font-weight: bold;
  color: var( --text-color); /* Colore del testo principale */
}

.card .ant-card-meta-description {
  font-size: 16px; /* Descrizione più piccola sotto il valore (ad es. "Valutazione Media") */
  color: var(--text-color);
}


/* Contenitore della tabella che ora occupa tutto lo spazio */
.table-container {
  width: 100%; /* Fullwidth per la tabella */
  padding: 20px;
}

.assessment-container {
  padding: 20px;
}

/* Miglioramenti alle card */
.card {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  border-radius: 12px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

/* Miglioramenti alla tabella */
.table-row-light {
  background-color: var(--stone-100); /* Sfondo chiaro per righe pari */
}

.table-row-dark {
  background-color: var(--stone-50); /* Sfondo scuro per righe dispari */
}

.table th, .table td {
  padding: 12px;
  text-align: center;
  font-size: 14px;
  color: var(--text-color); /* Testo scuro */
}

.table .improvement-positive {
  color: var(--success-color);
  font-weight: bold;
}

.table .improvement-negative {
  color: var(--error-color);
  font-weight: bold;
}

/* Barre di progresso circolari */
.circular-progress {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Pulsanti più interattivi */
.primary-button {
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 8px;
  padding: 8px 16px;
}

.primary-button:hover {
  background-color: darken(var(--primary-color), 10%);
  transform: scale(1.05);
}
