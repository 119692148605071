/* LeadershipSimulator.css */

/* Sezione Superiore */
.user-info-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border: 1px solid #e7e5e4; /* Aggiunge il bordo con il colore specificato */
    border-radius: 8px;

  }
/* Sezione delle Competenze */
.competencies-row {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 16px 0;
    border: 1px solid #e7e5e4; /* Aggiunge il bordo con il colore specificato */
    border-radius: 8px;
  }
  
  .competency-box {
    flex: 1 1 15%;
    text-align: center;
    margin: 8px;
    min-width: 120px;
    
  }
    /* Sezione Inferiore */
.bottom-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
  }
  
  .scenario-column {
    flex: 2;
    margin-right: 16px;
    min-width: 300px;
    border: 1px solid #e7e5e4; /* Aggiunge il bordo con il colore specificato */
    border-radius: 8px;

  }
  
  .email-column {
    flex: 1;
    min-width: 200px;
    border: 1px solid #e7e5e4; /* Aggiunge il bordo con il colore specificato */
    border-radius: 8px;
    height: 100%;
  }

  
  /* Pulsante Inventario */
  .inventory-button {
    margin-left: auto;
  }
  
  /* Assicurati che il contenuto sia responsive */
  @media (max-width: 768px) {
    .bottom-section {
      flex-direction: column;
    }
  
    .scenario-column, .email-column {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
  