.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 800px;
  background-color: #f9f9f9;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  margin: auto; /* Centra orizzontalmente */
}

.progress-bar {
  background-color: #f0f0f0; /* Colore di sfondo più tenue */
  height: 10px;
  border-radius: 5px;
}
.progress-bar-fill {
  background-color: #0c0a09; /* Colore della progress bar attiva */
  border-radius: 5px;
  transition: width 0.4s ease;
}


.chat-list {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;
}

.chat-input-container {
  border-radius: 20px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #d1d1d6;
  outline: none;
}

.message-container {
  display: flex;
  align-items: center; /* Cambia allineamento per icona e messaggio */
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 60%; /* Regola la larghezza dei messaggi */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.message-container.user {
  background-color: #6f72fc; /* Colore per il messaggio dell'utente */
  color: black;
  align-self: flex-end;
  border-radius: 20px 20px 0 20px;
  margin-left: auto; /* Allinea a destra */
}

.message-container.bot {
  background-color: #e5e5ea; /* Colore per il messaggio del bot */
  color: #000;
  align-self: flex-start;
  border-radius: 20px 20px 20px 0;
  margin-right: auto; /* Allinea a sinistra */
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff; /* Sfondo bianco per le icone */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 10px; /* Aggiungi spazio tra icona e messaggio */
}

.icon-container.user {
  margin-left: 10px; /* Posiziona l'icona a destra per l'utente */
}

.icon-container.bot {
  margin-right: 10px; /* Posiziona l'icona a sinistra per il bot */
}

.user-avatar, .bot-avatar {
  border-radius: 50%; /* Icona rotonda */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Ombra leggera */
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
