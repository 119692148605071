/* src/styles/theme.css */
:root {
    --stone-50: #fafaf9;
    --stone-100: #f5f5f4;
    --stone-200: #e7e5e4;
    --stone-300: #d6d3d1;
    --stone-400: #a8a29e;
    --stone-500: #78716c;
    --stone-600: #57534e;
    --stone-700: #44403c;
    --stone-800: #292524;
    --stone-900: #1c1917;
  
    /* Colori base per il tema iniziale */
    --background-color: var(--stone-50); /* Bianco/chiaro */
    --text-color: var(--stone-900); /* Testo scuro */
    --primary-color: #007aff; /* Blu per i pulsanti principali */
    --success-color: #4caf50; /* Verde per successi */
    --error-color: #ff3b30; /* Rosso per errori */
    --border-color: var(--stone-300); /* Colore per bordi */
    
  }
  
  /* Stile card globale, specifico per il progetto */
.custom-card {
  border-radius: 8px; /* Imposta il border-radius a 8px */
  border: 1px solid #e7e5e4; /* Aggiunge il bordo con il colore specificato */
  padding: 20px; /* Aggiunge padding interno */
  background-color: white; /* Sfondo bianco per le card */
}
/* Bottoni principali scuri */
.ant-btn-primary {
  background-color: #0c0a0a !important;  /* Dark Stone Gray */
  border-color: #0c0a0a !important;
  color: #E2E8F0 !important;  /* Light Gray Text */
}

.ant-btn-primary:hover {
  background-color: #0c0a0a !important;  /* Darker Stone Gray */
  border-color: #0c0a0a !important;
  color: #E2E8F0 !important;
}

/* Bottoni secondari scuri */
.ant-btn-default {
  background-color: #0c0a0a !important;  /* Stone Gray */
  border-color: #0c0a0a !important;
  color: #E2E8F0 !important;
}

.ant-btn-default:hover {
  background-color: #4A5568 !important;  /* Dark Stone Gray */
  border-color: #4A5568 !important;
  color: #E2E8F0 !important;
}
/* Cambia il colore dei link */
a {
  color: #4A5568; /* Stone Gray */
  text-decoration: none; /* Rimuove la sottolineatura */
}

a:hover {
  color: #2D3748; /* Very Dark Stone Gray */
  text-decoration: underline; /* Sottolineatura al passaggio del mouse */
}
/* Link specifici per la card */
.card a {
  color: #4A5568; /* Stone Gray */
}

.card a:hover {
  color: #2D3748 !important; /* Very Dark Stone Gray */
  text-decoration: underline !important; /* Sottolineatura al passaggio del mouse */
}
