/* style.css */

body {
    background-color: #f0f2f5;
  }
  
  .ant-card {
    border-radius: 8px;
  }
  
  .ant-layout-header {
    background-color: #001529;
  }
  