/* src/styles/AssessmentForm.css */

/* Container per l'intera pagina del form */
.assessment-form-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 16px;
  }
  
  /* Stile per il container dello slider orizzontale dei template */
  .assessment-form-slider {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    overflow-x: auto;
    padding-bottom: 10px;
    scrollbar-width: thin;
  }
  
  .assessment-form-slider::-webkit-scrollbar {
    height: 8px;
  }
  
  .assessment-form-slider::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  
  /* Stile per le card dei template */
/* Stile per le card dei template */
.assessment-form-card {
    width: 300px; /* Larghezza fissa */
    height: 200px; /* Altezza fissa */
    border-radius: 8px;
    border: 1px solid #e7e5e4; /* Aggiunge il bordo con il colore specificato */
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Nascondere tutto ciò che esce dai limiti della card */
    text-align: center; /* Centrare il testo per uniformità */
    padding: 10px; /* Aggiungi padding per evitare che il testo tocchi i bordi */
  }

  
  .assessment-form-card.active {
    background-color: #1c1917;
    color: white;
  }
  
  .assessment-form-card-title {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 8px;
    color: inherit; /* Utilizza il colore corrente della card */
  }
  
  .assessment-form-card-description {
    font-size: 0.9em;
    color: #666; /* Cambia il colore per il testo della descrizione */
    line-height: 1.2em;
    height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  
  .assessment-form-card.active .assessment-form-card-title,
  .assessment-form-card.active .assessment-form-card-description {
    color: white; /* Cambia il colore del testo quando la card è attiva */
  }
  
  
  /* Stile per i pulsanti interni */
  .assessment-form-button {
    transition: background-color 0.3s ease, transform 0.2s ease;
    border-radius: 8px;
    padding: 8px 16px;
  }
  
  .assessment-form-button:hover {
    background-color: darken(var(--primary-color), 10%);
  }
  
  /* Stile per i componenti input e textarea */
  .assessment-form-input {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .assessment-form-textarea {
    width: 100%;
    margin-bottom: 16px;
  }
  
  /* Stile per il container degli allegati */
  .assessment-form-file-container {
    padding: 20px;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
    height: fit-content;
  }
  
  .assessment-form-upload-button {
    margin-bottom: 16px;
  }
  .assessment-scroll-container {
    display: flex;
  gap: 16px;
  overflow-x: auto;
  padding-bottom: 10px;
  scrollbar-width: thin;
  
    &::-webkit-scrollbar {
      height: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 8px;
    }
  
    & > * {
      flex: 0 1 calc(33.3333% - 16px);
      margin-right: 16px;
    }
  }
  .assessment-scroll-container > * {
    flex: 0 0 auto; /* Le card non cambiano dimensione, ma sono scorrevoli */
  }
  