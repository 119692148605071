/* Stili per le card nella dashboard */

/* Stile base per tutte le card */
.dashboard-card {
    width: 240px;  /* Larghezza fissa per le card */
    height: 120px; /* Altezza fissa per le card */
    background-color: white;
    border-radius: 8px;  /* Bordo arrotondato */
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    border: 1px solid #e7e5e4;  /* Aggiunge un bordo sottile */
  }
  

  /* Stile per l'icona all'interno della card */
  .dashboard-card .icon {
    font-size: 20px;
    margin-bottom: 0px;
  }
  

  /* Stile per il numero principale (ad esempio "45,231.89") */
  .dashboard-card .number {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
    color: #333;  /* Colore del testo */
  }
  
  /* Stile per la descrizione sotto il numero (ad esempio "Ritorno Investimento") */
  .dashboard-card .label {
    font-size: 14px;
    color: #555;
  }
  .performance-list-custom-margin {
    margin-top: 10px; /* Riduci questo valore secondo necessità */
  }
  
  